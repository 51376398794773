<template>
  <footer class="footer">
    <div class="footer-background">
      <div>
        <a
          href="https://github.com/youmu257/76button"
          target="_blank"
          class="text-dark"
        >
          歡迎在 Github 提出任何建議
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="#99999"
            class="bi bi-github"
            viewBox="0 0 16 16"
          >
            <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z" />
          </svg>
        </a>
        或填寫
        <a
          href="https://forms.gle/ZtSFH3jLDnd1ocAH8"
          target="_blank"
        >
          回饋表單
        </a>
        <div>本站為粉絲純做推廣用，與 STORIA 沒有任何關係</div>
        <div>
          Icons made by <a
            href="https://www.freepik.com"
            title="Freepik"
            target="_blank"
          >Freepik</a> from <a
            href="https://www.flaticon.com/"
            title="Flaticon"
            target="_blank"
          >www.flaticon.com</a>
        </div>
        <div>
          感謝 Cow Lo 提供
          <a
            href="https://twitter.com/ud83xji4g/status/1444297977388945411?s=21"
            target="_blank"
          >圖片素材</a> |
          感謝 老爹 提供
          <a
            href="https://discord.com/channels/699160319960023071/833581544223277068/901992965520638032"
            target="_blank"
          >彩蛋素材</a> |
          感謝 阿圖 設計的按鈕樣式 |
          感謝 瑋ホン 提供推特音訊檔
        </div>
        <div>v.{{ appVersion }}</div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'VoicePageFooter',
  data() {
    return {
      // 取得版本號
      appVersion: process.env.VUE_APP_VERSION,
    }
  },
}
</script>
<style scoped src="../css/Footer.css"></style>